import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../css/juster.css";
import "../css/animation.css";
import SyncClaims from "../images/ic-sync-claims.svg";
import icScheduleAppointments from "../images/ic-appointment.svg";
import ScheduleAppointments from "../images/schedule_inspection.gif";
import IcSyncCalendars from "../images/ic-sync-calendar.svg";
import SyncCalendars from "../images/sync_calendar.gif";
import IcNavigation from "../images/ic-navigate.svg";
import Navigation from "../images/navigation_f.gif";
import IcWorkOffline from "../images/ic-sync-claims.svg";
import WorkOffline from "../images/offline_functionality.gif";
import IcTakePhotosVideos from "../images/ic-photos.svg";
import TakePhotosVideos from "../images/take_photos_videos.gif";
import IcScanDocuments from "../images/ic-scan.svg";
import ScanDocuments from "../images/scan_documents.gif";
import IcChecklist from "../images/ic-checklist.svg";
import Checklist from "../images/checklist_f.gif";
import IcCaptionPhotos from "../images/ic-photo-captioning.svg";
import CaptionPhotos from "../images/caption_photos.gif";
import IcSmartimagegrouping from "../images/ic-smart-grouping.svg";
import Smartimagegrouping from "../images/smart_grouping.gif";
import IcCloudStorage from "../images/ic-cloud-storage.svg";
import IcRoutePlanning from "../images/ic-route-planning.svg";
import RoutePlanning from "../images/route_plan.jpg";
import IcBuildScopingReports from "../images/ic-scoping.svg";
import BuildScopingReports from "../images/publish_scoping_report.gif";
// import Rellax from '../js/rellax';
import { Helmet } from "react-helmet"
// const $ = window.jquery

export default class Features extends React.Component{
  componentDidMount() {
      const $ = window.jQuery
      $(document).ready(function () {
        var navpos = $('#mainnav').offset();
        var navpos = $('#tutorials-container').offset();
        $(window).bind('scroll', function () {
            if ($(window).scrollTop() >= 120) {
                $('#tutorials-container').addClass('tutorials-container-fadeUp');
                $('#topnav').removeClass('tutorials-container-fadeUp');
            }
            else {
                $('#topnav').addClass('tutorials-container-fadeUp');
                $('#tutorials-container').removeClass('tutorials-container-fadeUp');
            }
            if ($(window).scrollTop() >= 300) {
                $('#mainnav').addClass('navbar-fixed-top');
                $('#topnav').removeClass('navbar-fixed-top');
            }
            else {
                $('#topnav').addClass('navbar-fixed-top');
                $('#mainnav').removeClass('navbar-fixed-top');
            }
        });

        if( /Android|webOS|iPhone|iPadPro|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {

            $('.tutorial-heading').on('click', function() {
                const parentEle = $(this).parent();
                if(parentEle.hasClass('open')){
                    $(".accordion>h4[class='active']").trigger('click');
                    // $(".tabBlock-tabs[class='is-active']").trigger('click');
                }
                parentEle.toggleClass('open').slideDown();
            });
        }
    });
    


    $(document).ready(function ($) {
        //Set default open/close settings
        var divs = $('.accordion>div').hide(); //Hide/close all containers
        var divs = $('.accordion>div.first-element').show();
        var h4s = $('.accordion>h4').click(function () {
            h4s.not(this).removeClass('active')
            $(this).toggleClass('active')
            divs.not($(this).next()).slideUp()
            $(this).next().slideToggle()
            return false; //Prevent the browser jump to the link anchor

        });

    });

    window.onhashchange = focusByHash

    document.addEventListener('DOMContentLoaded', focusByHash, false);

    function focusByHash() {
      var hash = window.location.hash;
      if(hash.length > 0) {
        var qString = "a[href='" + hash + "']";
        var el = $(".tabBlock-tabs").find(qString).closest('li');
          if(el.length > 0) {
            el.trigger('click');
          }
        }
    }
    var TabBlock = {
      s: {
          animLen: 500
      },

      init: function () {
          TabBlock.bindUIActions();
          TabBlock.hideInactive();
      },

      bindUIActions: function () {
          $('.tabBlock-tabs').on('click', '.tabBlock-tab', function () {
              TabBlock.switchTab($(this));
              if( /Android|webOS|iPhone|iPadPro|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                  $('.tutorial-heading').trigger('click')
              }
          });
      },

      hideInactive: function () {
          var $tabBlocks = $('.tabBlock');

          $tabBlocks.each(function (i) {
              var
                  $tabBlock = $($tabBlocks[i]),
                  $panes = $tabBlock.find('.tabBlock-pane'),
                  $activeTab = $tabBlock.find('.tabBlock-tab.is-active');
              $panes.hide();
              $($panes[$activeTab.index()]).show();
          });
      },

      switchTab: function ($tab) {
          var $context = $tab.closest('.tabBlock');

          if (!$tab.hasClass('is-active')) {
              $tab.siblings().removeClass('is-active');
              $tab.addClass('is-active');

              TabBlock.showPane($tab.index(), $context);
          }
      },

      showPane: function (i, $context) {
          var $panes = $context.find('.tabBlock-pane');

          // Normally I'd frown at using jQuery over CSS animations, but we can't transition between unspecified variable heights, right? If you know a better way, I'd love a read it in the comments or on Twitter @johndjameson
          $panes.slideUp(TabBlock.s.animLen);
          $($panes[i]).slideDown(TabBlock.s.animLen);
      }
    };

    $(function () {
      TabBlock.init();
    });
  }
  handleAccordianClick(event) {
    const $ = window.jQuery
    if( /Android|webOS|iPhone|iPadPro|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        $('.tutorial-heading').on('click', function() {
            const parentEle = $(this).parent();
            if(parentEle.hasClass('open')){
                $(".accordion>h4[class='active']").trigger('click');
                // $(".tabBlock-tabs[class='is-active']").trigger('click');
            }
            parentEle.toggleClass('open').slideDown();
        });
    }
  }
  render () {
    return (
      <>
      <Helmet>
          <title>Features of the Field Inspection App for Adjuster | JustEZ</title>          
          <meta name="description" content="JustEZ Feature-  Manage inspections assignments, schedule, navigate, dropbox integrations, take photos/videos, caption photos, answer checklist, final scoping report."/>
          <meta name="keywords" content="field inspection,insurance adjusters,adjusters,loss site,inspection reports,claim adjusters,property inspection,inspection,sync claims,inspections,scoping reports" />
        </Helmet>
    <Header></Header>

    <div class="tp-integrations how_it_works_b tp-tutorial-banner">
      <div class="container tp-integrations-container">
        <div class="integrations-info">
          <h2>How JustEZ helps you do more</h2>
          <p>
            JustEZ has many features that helps adjusters in their field
            inspection process. You get everything that is required to come up
            with final estimations. Check out our comprehensive list of all
            features that make adjusters more efficient.
          </p>
        </div>
      </div>
    </div>

    <div class="container-main">
      <div class="container tutorials-main tabBlock">
        <section class="tabBlock-content">
          <div class="tabBlock-pane fadeInDown">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img alt="Sync Claims" title="Sync Claims" src={SyncClaims} />
              </span>
              Sync Claims
            </h3>
            <p>
              Sync claims and appointments to your smartphones or tablets in one
              tap.
            </p>
            <h4>Support for Claim management systems</h4>
            <p>
              JustEZ supports claim management system such as ClaimColony and
              XactAnalysis. Now, sync up claim statuses/notes from these systems
              to app directly, and get started with field inspection.
            </p>
            <h4>Integration with cloud storage program</h4>
            <p>
              Effortlessly create inspections, sync media to cloud with our
              integration with Dropbox. It not only ensures that your data is
              safe but also make it accessible for you from anywhere in the
              world.
            </p>
            <h4>Create claims using any email client</h4>
            <p>
              Simplify the process of managing inspections by sending/forwarding
              emails to create claims. You can either use regular email or
              appointment email from any of email client services such as
              Outlook, Gmail etc.
            </p>
          </div>

          <div id="scheduleappointments" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img
                  alt="Schedule Appointments"
                  title="Schedule Appointments"
                  src={icScheduleAppointments}
                />
              </span>
              Schedule Appointments
            </h3>
            <p>
              Schedule appointments with your customers while not conflicting
              with your personal agenda.
            </p>
            <img
              class="img-responsive"
              alt="Schedule Appointments"
              title="Schedule Appointments"
              src={ScheduleAppointments}
            />
            <h4>Proactive scheduling</h4>
            <p>
              JustEZ allows you to schedule an appointment in a proactive
              manner. It understands your work style and suggests every
              assignment with priorities. This way, the field inspection process
              becomes more efficient and with less administrative headache.
            </p>
            <h4>Plan inspection schedules</h4>
            <p>
              JustEZ knows about your daily roster and can tell how far away
              each inspection is. Thus, it helps in better planning of
              inspection schedules.
            </p>
          </div>

          <div id="synccalendars" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img
                  alt="Sync Calendars"
                  title="Sync Calendars"
                  src={IcSyncCalendars}
                />
              </span>
              Sync Calendars
            </h3>
            <p>
              Sync your phone calendar and let the app remind you of your
              scheduled inspections.
            </p>
            <img
              class="img-responsive"
              alt="Sync Calendars"
              title="Sync Calendars"
              src={SyncCalendars}
            />
            <h4>Sync to your phone calendar</h4>
            <p>
              Synchronize calendar to see inspections on your phone calendar. It
              brings down everything at a central place, making it easy for you
              to plan and schedule your work.
            </p>
            <h4>Get reminders for scheduled inspection</h4>
            <p>
              Start receiving reminders for all scheduled inspections and never
              let important appointments slip from your mind.
            </p>
          </div>

          <div id="navigation" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img alt="Navigation" title="Navigation" src={IcNavigation} />
              </span>
              Navigation
            </h3>
            <p>
              No more typing address in your Car’s GPS. JustEZ integrates with
              Waze and Google Maps. Simply drive to the loss site while app
              takes care of directions.
            </p>
            <img
              class="img-responsive"
              alt="Navigation"
              title="Navigation"
              src={Navigation}
            />
            <h4>Get live traffic updates with Waze and Google maps</h4>
            <p>
              Our integration with real-time navigation apps provides you live
              traffic updates, fastest route along with the information of when
              you'll arrive to loss site; making it supereasy to reach to loss
              site.
            </p>
            <h4>Drive with any maps of your preference</h4>
            <p>
              Choose a map of your preference and drive to loss site without
              typing in the address. JustEZ picks the address automatically and
              provides you the best driving experience ever.
            </p>
          </div>

          <div id="workoffline" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img
                  alt="Work Offline"
                  title="Work Offline"
                  src={IcWorkOffline}
                />
              </span>
              Work Offline
            </h3>
            <p>
              Work on all inspection activities such as taking photos and
              captioning them, answering checklist etc. without network
              connectivity.
            </p>
            <img
              class="img-responsive"
              alt="Work Offline"
              title="Work Offline"
              src={WorkOffline}
            />
            <h4>Build a reliable and secure environment</h4>
            <p>
              JustEZ works in offline mode too. You can work on inspections in
              the same way as it works with internet connectivity. Thus, making
              it more reliable and secure for our users.
            </p>
            <h4>Better experience when connection is slow</h4>
            <p>
              You can work on inspections even when the connection is slow,
              flickering or not working. All your actions will sync to cloud as
              soon as you connect to the internet again. This way, you get a
              better experience of working on inspections.
            </p>
          </div>

          <div id="takephotosvideos" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img
                  alt="Take Photos & Videos"
                  title="Take Photos & Videos"
                  src={IcTakePhotosVideos}
                />
              </span>
              Take Photos & Videos
            </h3>
            <p>
              We know adjusters hate to use your expensive smartphone to take
              photos during field inspection. Now take photos, videos from your
              very own camera and import them to app without any hassle.
            </p>
            <img
              class="img-responsive"
              alt="Take Photos & Videos"
              title="Take Photos & Videos"
              src={TakePhotosVideos}
            />
            <h4>Support for Flashair wifi SD cards</h4>
            <p>
              Our integration with Flashair wifi SD cards enables a private
              connection between the app and camera. Now, there is no need to
              transfer media manually, as it gets synced to inspection itself.
            </p>
            <h4>Import photos from camera</h4>
            <p>
              Easily import photos from cameras such as Sony, Olympus and Nikon.
            </p>
            <h4>Add annotations for better documentation</h4>
            <p>
              Draw lines or use different shapes to highlight any specific area
              in images and add comments to it. This way, you can clearly and
              accurately identify the damages in images. Thus, there are more
              reliable field inspection reports.
            </p>
          </div>

          <div id="scandocuments" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img
                  alt="Scan Documents"
                  title="Scan Documents"
                  src={IcScanDocuments}
                />
              </span>
              Scan Documents
            </h3>
            <p>
              Scan invoices, payment bills to add to your inspection reports
              without any fear of losing the copy.
            </p>
            <img
              class="img-responsive"
              alt="Scan Documents"
              title="Scan Documents"
              src={ScanDocuments}
            />
            <h4>Easily make a copy of documents and save on cloud</h4>
            <p>
              JustEZ lets you scan documents/receipts of any emergency work
              carried out at loss site. It creates a copy of that document and
              saves it on cloud; making it easy for you to access.
            </p>
          </div>

          <div id="checklist" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img alt="Checklist" title="Checklist" src={IcChecklist} />
              </span>
              Checklist
            </h3>
            <p>
              Keep a checklist and answer them during the inspection, so you
              won’t leave a thing.
            </p>
            <img
              class="img-responsive"
              alt="Checklist"
              title="Checklist"
              src={Checklist}
            />
            <h4>Save time with predefined checklist</h4>
            <p>
              JustEZ offers a predefined checklist which not only saves time of
              adjusters during field inspection but also ensures you never miss
              asking any important information from an insured.
            </p>
            <h4>Get things done faster as you never have to recall them</h4>
            <p>
              With a checklist in your hand, you never to recall on what you
              have to do. So, you do things faster and with more accuracy.
            </p>
          </div>

          <div id="captionphotos" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img
                  alt="Caption Photos"
                  title="Caption Photos"
                  src={IcCaptionPhotos}
                />
              </span>
              Caption Photos
            </h3>
            <p>
              Use our AI-based photo captioning technology. It takes 3 minutes
              to caption 80+ photos from tablet.
            </p>
            <img
              class="img-responsive"
              alt="Caption Photos"
              title="Caption Photos"
              src={CaptionPhotos}
            />
            <h4>Save time by narrating captions</h4>
            <p>
              JustEZ has in-built voice recognition, gives you the option to add
              overview to images by narrating it. This is the most faster way to
              add captions as your speech becomes text to the overview of image.
            </p>
            <h4>Choose a title from title suggestions</h4>
            <p>
              We use AI based photo captioning technology. It shows you title
              suggestions based on the objects shown in image. You can choose a
              title from suggestion and title images quickly and aptly.
            </p>
            <h4>Add overview with smart sentence suggestions</h4>
            <p>
              JustEZ makes it way simpler to add overview to images. It provides
              you sentence suggestions based on the loss description. You can
              pick suggestions and add overview to images more promptly.
            </p>
          </div>

          <div id="smartimagegrouping" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img
                  alt="Smart image grouping"
                  title="Smart image grouping"
                  src={IcSmartimagegrouping}
                />
              </span>
              Smart Image Grouping
            </h3>
            <p>Add a title to the group of images altogether.</p>
            <img
              class="img-responsive"
              alt="Caption Photos"
              title="Caption Photos"
              src={Smartimagegrouping}
            />
            <h4>Save time by adding a title to the group of images</h4>
            <p>
              JustEZ smart titling does grouping of all images by identifying
              the objects in them. You can accept the title name for adding
              titles to any group of images.
            </p>
            <h4>Change the title of images under groups</h4>
            <p>
              You can easily change the title of any image/images from the list
              of previously added titles. It will change the image title and its
              group name.
            </p>
          </div>

          <div id="cloudstorage" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img
                  alt="Cloud Storage"
                  title="Cloud Storage"
                  src={IcCloudStorage}
                />
              </span>
              Cloud Storage
            </h3>
            <p>Store all your photos and documents in cloud for easy access.</p>
            <h4>Access stored data from anywhere in few clicks</h4>
            <p>
              JustEZ integrates with Dropbox; a cloud storage program. All
              media, scoping reports gets synced to it and making it accessible
              for you from anywhere in the world. This is also the most secure
              way of storing data.
            </p>
          </div>

          <div id="routeplanning" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img
                  alt="Route Planning"
                  title="Route Planning"
                  src={IcRoutePlanning}
                />
              </span>
              Route Planning
            </h3>
            <p>Plan your driving according to your schedule.</p>
            <img
              class="img-responsive"
              alt="Route Planning"
              title="Route Planning"
              src={RoutePlanning}
            />
            <h4>Pick the best route for you</h4>
            <p>
              JustEZ lets you schedule appointments by picking the best route.
              You can sort it by time and distance and accordingly, plan on your
              visits to loss site.
            </p>
            <h4>Reduce travel time and serve more customers</h4>
            <p>
              Using route planning feature, you can plan driving according to
              your schedule. It reduces your travel time and with that saved
              time, you can serve more customers.
            </p>
          </div>

          <div id="buildscopingreports" class="tabBlock-pane">
            <h3 class="feature_head_ctr">
              <span class="ft_span_ctr">
                <img
                  alt="Build Scoping Reports"
                  title="Build Scoping Reports"
                  src={IcBuildScopingReports}
                />
              </span>
              Build Scoping Reports
            </h3>
            <p>JustEZ is a great tool for building final scoping reports.</p>
            <img
              class="img-responsive"
              alt="Build Scoping Reports"
              title="Build Scoping Reports"
              src={BuildScopingReports}
            />
            <h4>Save time in creating reports</h4>
            <p>
              Caption photos, answer the checklist and you are done with the
              scoping reports. You save a lot of your time as reports get
              generated itself in JustEZ as you complete the inspection.
            </p>
            <h4>Publish reports on the go</h4>
            <p>
              You can publish scoping reports or share it with examinar while
              you drive to the next inspection.
            </p>
          </div>
        </section>

        <aside>
          <div class="tutorial-heading">
            <div class="headnav">
            <h3 id="tutorialHeading">Features</h3>
            <span class="arrow">
              <svg viewBox="0 0 256 512">
                <path
                  fill=""
                  d="M136.5 185.1l116 117.8c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L128 224.7 27.6 326.9c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17l116-117.8c4.7-4.6 12.3-4.6 17 .1z"
                  class=""
                ></path>
              </svg>
            </span>
            </div>
          </div>
          <div class="accordion mainnav" id="mainnav">
            <div class="first-element">
              <ul class="tabBlock-tabs">
                <li
                  class="tabBlock-tab is-active"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#syncclaims">Sync Claims</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#scheduleappointments">Schedule Appointments</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#synccalendars">Sync Calendars</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#navigation">Navigation</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#workoffline">Work Offline</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#takephotosvideos">Take Photos & Videos</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#scandocuments">Scan Documents</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#checklist">Checklist</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#captionphotos">Caption Photos</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#smartimagegrouping">Smart Image Grouping</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#cloudstorage">Cloud Storage</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#routeplanning">Route Planning</a>
                </li>
                <li
                  class="tabBlock-tab"
                  onClick={this.handleAccordianClick}
                >
                  <a href="#buildscopingreports">Build Scoping Reports</a>
                </li>
              </ul>
            </div>
          </div>
        </aside>
      </div>
    </div>

    <Footer></Footer>
  </>
    )
  }
}